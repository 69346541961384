import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Map, TileLayer, Marker, Polyline, Polygon, Popup } from 'react-leaflet';
import get from 'lodash/get';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

import { GET_LIST } from './queries';
import Spinner from './Spinner';
import Error from './Error';

const List = props => {
  const { trafficAnnouncements } = props;

  return (
    <ul>
      {trafficAnnouncements.map(item => (
        <li key={item.announcementId}>
          <Link to={`/update/${item.announcementId}`}>{`${item.title.fi} (${item.publishedVersion ? 'JULKAISTU' : 'EI JULKAISTU'})`}</Link>
        </li>
      ))}
    </ul>
  );
}

const ItemPopup = props => {
  const { item } = props;

  return (
    <Popup>
      <h4>{`${item.title.fi} (${item.publishedVersion ? 'JULKAISTU' : 'EI JULKAISTU'})`}</h4>
      <div>
        <Link to={`/update/${item.announcementId}`}>Muokkaa</Link>
      </div>
    </Popup>
  );
}

const MapItems = props => {
  const { trafficAnnouncements } = props;

  return (
    trafficAnnouncements.map(item => {
      const features = get(item, 'geojson.features');

      if (!features) {
        return [];
      }

      return features.map((feature, key) => {
        const geometry = feature.geometry;

        if (!geometry) {
          return null;
        }

        if (geometry.type === 'LineString') {
          const lineCoordinates = geometry.coordinates.map(coordinate => [...coordinate].reverse());
          return [
            <Polyline color="red" positions={lineCoordinates} key={`${item.announcementId}_${key}`}>
              <ItemPopup item={item}/>
            </Polyline>,
            <Marker position={lineCoordinates[0]} key={`${item.announcementId}_${key}_first`}>
              <ItemPopup item={item}/>
            </Marker>,
            <Marker position={lineCoordinates.slice(-1)[0]} key={`${item.announcementId}_${key}_last`}>
              <ItemPopup item={item}/>
            </Marker>
          ];
        } else if (geometry.type === 'Polygon') {
          const polygonCoordinates = geometry.coordinates[0].map(coordinate => [...coordinate].reverse());
          return (
            <Polygon color="purple" positions={polygonCoordinates} key={`${item.announcementId}_${key}`}>
              <ItemPopup item={item}/>
            </Polygon>
          );
        } else {
          const coordinates = [...geometry.coordinates].reverse();
          return (
            <Marker position={coordinates} key={`${item.announcementId}_${key}`}>
              <ItemPopup item={item}/>
            </Marker>
          );
        }
      });
    })
  );
}

const Pagination = props => {
  const { pageNumber, handlePageNumber, listLength, resetPageNumber } = props

  return (
    <div className='pagination'>
      {pageNumber > 0 ? <button onClick={() => handlePageNumber(false)} className='pagination_button'>&lt; Edellinen sivu</button> : ''}
      {pageNumber > 0 ? <button onClick={() => resetPageNumber()} className='pagination_button'>Palaa alkuun</button> : ''}
      {listLength >= 100 ? <button onClick={() => handlePageNumber(true)} className='pagination_button'>Seuraava sivu &gt;</button> : ''}
    </div>
  );
}

const mapConfig = {
  //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Oulunliikenne',
  center: [ 65.01236, 25.46816 ],
  zoomLevel: 14,
  searchZoomLevel: 16,
  style: { height: '75vh' },
};

class ListPage extends Component {
  state = {
    modesOfTransport: [],
    active: false,
    future: false,
    old: false,
    published: false,
    unpublished: false,
    hasStartAndEndDate: false,
    pageNumber: 0,
  };

  resetPageNumber = () => {
    this.setState({
      ...this.state,
      pageNumber: 0,
    })
  }

  handlePageNumber = increase => {
    const nextPage = increase ? this.state.pageNumber + 1 : this.state.pageNumber - 1
    this.setState({
      ...this.state,
      pageNumber: Math.max(nextPage, 0)
    })
  }

  handleChange = evt => {
    const { name, checked, value } = evt.target;
    if (Array.isArray(this.state[name])) {
      const items = this.state[name].filter(item => item !== value);
      if (checked) {
        items.push(value);
      }
      this.setState({ [name]: items });
    } else {
      this.setState({ [name]: checked });
    }
  };

  filter = () => {
    const {
      modesOfTransport,
      active,
      future,
      old,
      published,
      unpublished,
      hasStartAndEndDate,
    } = this.state;

    return (
      <FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name="modesOfTransport"
                checked={modesOfTransport.includes('PUBLIC_TRANSPORT')}
                value="PUBLIC_TRANSPORT"
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Julkinen liikenne"
          />
          <FormControlLabel
            control={
              <Switch
                name="modesOfTransport"
                checked={modesOfTransport.includes('CAR')}
                value="CAR"
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Auto"
          />
          <FormControlLabel
            control={
              <Switch
                name="modesOfTransport"
                checked={modesOfTransport.includes('BICYCLE')}
                value="BICYCLE"
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Pyöräily"
          />
          <FormControlLabel
            control={
              <Switch
                name="modesOfTransport"
                checked={modesOfTransport.includes('PEDESTRIAN')}
                value="PEDESTRIAN"
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Kävely"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name="active"
                checked={active}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Aktiiviset"
          />
          <FormControlLabel
            control={
              <Switch
                name="future"
                checked={future}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Tulevat"
          />
          <FormControlLabel
            control={
              <Switch
                name="old"
                checked={old}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Vanhat"
          />
          <FormControlLabel
            control={
              <Switch
                name="hasStartAndEndDate"
                checked={hasStartAndEndDate}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Toistaiseksi voimassa"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name="published"
                checked={published}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Julkaistut"
          />
          <FormControlLabel
            control={
              <Switch
                name="unpublished"
                checked={unpublished}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Ei julkaistut"
          />
        </FormGroup>
      </FormGroup>
    );
  };

  render() {
    const {
      modesOfTransport,
      active,
      future,
      old,
      published,
      unpublished,
      hasStartAndEndDate,
      pageNumber,
    } = this.state;

    return (
      <div>
        <Query query={GET_LIST} variables={{ page: pageNumber }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Spinner message={'Haetaan tiedotteita'} />
            }

            if (error) {
              return <Error message={'Tiedotteiden hakemisessa on tilapäisesti ongelmia'} />
            }

            const { trafficAnnouncements } = data

            const filteredTrafficAnnouncements = trafficAnnouncements.filter(item => {
              let result = true;

              if (modesOfTransport.length) {
                result = item.modesOfTransport.some(mode => modesOfTransport.includes(mode));
              }

              if (active || future || old || hasStartAndEndDate) {
                result = result &&
                  ((active && moment().isBetween(item.startTime, item.endTime)) ||
                    (active && !item.endTime && moment() > moment(item.startTime)) ||
                    (future && moment() < moment(item.startTime)) ||
                    (old && moment() > moment(item.endTime)) ||
                    (hasStartAndEndDate && item.startTime && !item.endTime));
              }

              if (published || unpublished) {
                result = result &&
                  ((published && item.publishedVersion) ||
                  (unpublished && !item.publishedVersion));
              }

              return result;
            });

            return [
              <this.filter key="filter"/>,
              <Pagination 
                key="pagination"
                pageNumber={pageNumber}
                handlePageNumber={this.handlePageNumber}
                listLength={filteredTrafficAnnouncements.length}
                resetPageNumber={this.resetPageNumber}
              />,
              <List trafficAnnouncements={filteredTrafficAnnouncements} key="list"/>,
              <Map
                center={mapConfig.center}
                zoom={mapConfig.zoomLevel}
                style={mapConfig.style}
                fullscreenControl
                key="map"
              >
                <TileLayer url={mapConfig.url} attribution={mapConfig.attribution}/>
                <MapItems trafficAnnouncements={filteredTrafficAnnouncements}/>
              </Map>,
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default ListPage;
