import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import { AuthProvider, AuthConsumer } from './AuthContext';
import AppRouter from './AppRouter';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const App = () => (
  <AuthProvider>
    <AuthConsumer>
      {({ token }) => {
        return (
        <ApolloProvider client={new ApolloClient({
          uri: process.env.REACT_APP_GRAPHQL_URL,
          headers: {
            Authorization: token,
          },
          cache: new InMemoryCache({
            dataIdFromObject: o => o.announcementId
          }),
        })}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppRouter />
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      )}}
    </AuthConsumer>
  </AuthProvider>
);

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
