import React from 'react';
import { Map, TileLayer, withLeaflet } from 'react-leaflet';
import { ReactLeafletSearch } from 'react-leaflet-search';
import 'react-leaflet-fullscreen-control';

import MapDrawControls from './MapDrawControls';

const WrappedReactLeafletSearch = withLeaflet(ReactLeafletSearch);

const mapConfig = {
  //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Oulunliikenne',
  center: [ 65.01236, 25.46816 ],
  zoomLevel: 14,
  searchZoomLevel: 16,
  style: { height: '75vh' },
};


const FormMap = ({ onChange, onFocus, geoJson }) => {
  return (
    <Map
      center={mapConfig.center}
      zoom={mapConfig.zoomLevel}
      style={mapConfig.style}
      fullscreenControl
      onFocus={onFocus}
    >
      <TileLayer url={mapConfig.url} attribution={mapConfig.attribution}/>
      <WrappedReactLeafletSearch
        position="topleft"
        inputPlaceholder="Etsi osoite"
        showMarker={false}
        zoom={mapConfig.searchZoomLevel}
        showPopup={false}
        closeResultsOnClick={true}
        openSearchOnLoad={false}
      />
      <MapDrawControls onChange={onChange} geoJson={geoJson} />
    </Map>
  );
}

export default FormMap;
