import React, { Component } from 'react';
import { FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';

export default class MapDrawControls extends Component {
  editableFG = null;

  onChange = () => {
    const { onChange } = this.props;

    if (!this.editableFG || !onChange) {
      return;
    }

    const geojsonData = this.editableFG.leafletElement.toGeoJSON();
    onChange(geojsonData);
  };

  onFeatureGroupReady = (reactFGref) => {
    if (!reactFGref) {
      return;
    }

    let leafletGeoJSON = new L.GeoJSON(this.props.geoJson);

    leafletGeoJSON.eachLayer(layer => {
      reactFGref.leafletElement.addLayer(layer);
    });

    this.editableFG = reactFGref;
  };

  render() {
    return (
      <FeatureGroup
        ref={(reactFGref) => {
          this.onFeatureGroupReady(reactFGref);
        }}
      >
          <EditControl
            position='topright'
            onCreated={this.onChange}
            onEdited={this.onChange}
            onDeleted={this.onChange}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
            }}
          />
      </FeatureGroup>
    );
  }
}
