import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ProtectedRoute from './ProtectedRoute';
import Header from './Header';
import Home from './Home';
import Login from './Login';
import Create from './Create';
import List from './List';
import './AppRouter.scss';

const AppRouter = () => {
  return (
    <Router>
      <div className="page">
        <Header/>
        <div className="content">
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <ProtectedRoute path="/create" component={Create} />
          <ProtectedRoute path="/update/:id" component={Create} />
          <ProtectedRoute path="/list" component={List} />
        </div>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default AppRouter;
