import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorComponent } from 'react-material-ui-form-validator';

class FormCheckboxGroupValidator extends ValidatorComponent {
  onChange = evt => {
    this.setState({
      isValid: true,
    });
    this.props.onCheckboxChange(evt);
  }

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      options,
      onCheckboxChange,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <FormControl
        required
        error={!isValid}
        {...rest}
      >
        <FormLabel className="label-margin" required>
          {options.label}
        </FormLabel>
        <FormGroup>
          {options.items.map((item, key) =>
            <FormControlLabel
              control={
                <Checkbox
                  name={options.name}
                  checked={value.includes(item.value)}
                  onChange={this.onChange}
                  value={item.value}
                />
              }
              label={item.name}
              key={key}
            />
          )}
        </FormGroup>
        {this.errorText()}
      </FormControl>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  }
}

export default FormCheckboxGroupValidator;
