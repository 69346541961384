import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Spinner = ({ message }) => (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', margin: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', padding: '1rem 1rem 1rem 1rem', margin: 'auto', fontWeight: 'bold', fontSize: '1.5rem' }}>
            {message}
        </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', padding: '1rem 1rem 1rem 1rem', margin: 'auto' }}>
            <CircularProgress />
        </div>
    </div>
);

export default Spinner;